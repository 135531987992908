// npm
import React from 'react'
import { graphql } from 'gatsby'

// components
import Layout from '@organisms/global/Layout'

// images
import Sections from '@molecules/sections/Sections'

interface Props {
  data: Queries.DatoCmsErrorPageQuery
  location: Location
}

const ErrorPage = ({ data }: Props) => {
  const d = data.datoCmsErrorPage
  return (
    <Layout
      metadata={{
        seoTags: d.seoMetaTags,
      }}
      headerProps={{
        sizeVariant: 'normal',
        themeVariant: 'dark',
      }}
    >
      <Sections contentSections={d.videoBackgroundScreen} />
    </Layout>
  )
}

export default ErrorPage

export const pageQuery = graphql`
  query ErrorPage {
    datoCmsErrorPage {
      videoBackgroundScreen {
        ...contentSectionVideoBackground
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
